import languages from './languages';

const NextI18Next = require('next-i18next').default;
const path = require('path');

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: languages.defaultLanguageCode,
  fallbackLng: languages.defaultLanguageCode,
  localePath: path.resolve('./static/locales'),
  load: 'currentOnly',
  ignoreRoutes: ['/_next', '/static', '/data', '/logout', '/api'],
  otherLanguages: languages.otherLanguageCodes,
  localeSubpaths: {
    'en-US': 'en-US',
    'fi-FI': 'fi-FI',
  },
  serverLanguageDetection: false,
});

export const { appWithTranslation, config, i18n, Trans, withTranslation } = NextI18NextInstance;
