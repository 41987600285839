const languages = [
  { name: 'Deutsch', code: 'de-DE' },
  { name: 'English (UK)', code: 'en-GB' },
  { name: 'English (US)', code: 'en-US' },
  { name: 'Suomi', code: 'fi-FI' },
  { name: 'Français', code: 'fr-FR' },
];
const defaultLanguageCode = 'en-GB';

const otherLanguageCodes = languages
  .map(function getCodes(language) {
    return language.code;
  })
  .filter(function removeDefault(languageCode) {
    return languageCode !== defaultLanguageCode;
  });

module.exports.languages = languages;
module.exports.defaultLanguageCode = defaultLanguageCode;
module.exports.otherLanguageCodes = otherLanguageCodes;
